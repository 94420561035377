// Steps:
// check url
// fetch correct html file
// insert it in actual dom
// load all associated js scripts manually

const indexPaf = "/paf.index.html";
const indexPcl = "/pcl.index.html";

const regex = /(portail|cezam)/;
const hostname = window.location.hostname;

const isPaf = regex.test(hostname);

const htmlIndexToUse = isPaf ? indexPaf : indexPcl;

fetch(htmlIndexToUse, { cache: "reload" })
  .then((result) => result.text())
  .then((result) => {
    // replace HTML content
    const htmlDocument = document.querySelector("html");

    if (htmlDocument) {
      htmlDocument.innerHTML = result;
    }
    return result;
  })
  .then(() => {
    // load all script manually because innerHTML ignore scripts
    const scriptElements = document.querySelectorAll("script");

    Array.from(scriptElements).forEach((scriptElement) => {
      const clonedElement = document.createElement("script");

      Array.from(scriptElement.attributes).forEach((attribute) => {
        clonedElement.setAttribute(attribute.name, attribute.value);
      });

      clonedElement.text = scriptElement.text;

      if (scriptElement.parentNode) {
        scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
      }
    });
  });
